<style>
  main {
    background: #a1fac3;
    height: 100%;
  }</style>

<!-- routify:options index=3 -->
<main>
<br>
  <h1>Settings</h1>
</main>
